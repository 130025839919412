// @format

import _get from 'lodash/fp/get'
import _isEmpty from 'lodash/fp/isEmpty'
import React from 'react'
import styled from 'styled-components'
import Vimeo from '@vimeo/player'
import { media } from '../styles/theme'

const Container = styled.div`
  width: ${props => (props.aspectRatio > 1 ? '70%' : '60%')};
  max-width: 800px;
  margin: 0 auto;

  ${media(0)`
    width: ${props => (props.aspectRatio > 1 ? '70%' : '40%')};
  `};
`

const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 12px auto;
  padding-bottom: ${props => `${100 / props.aspectRatio}%`};
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Player = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`

class Media extends React.Component {
  state = {
    height: -1,
    width: -1,
  }

  constructor(props) {
    super(props)

    const { image } = props

    let height
    let imageUrl
    let width
    if (!_isEmpty(image)) {
      height = _get('fields.file.details.image.height')(image)
      width = _get('fields.file.details.image.width')(image)
      imageUrl = _get('fields.file.url')(image)
    }

    this.state = {
      height,
      imageUrl,
      width,
    }
  }

  componentDidMount() {
    const { onReady, vimeoUrl } = this.props

    if (!vimeoUrl) {
      if (onReady) {
        onReady()
      }

      return
    }

    // Initialise vimeo player
    const player = new Vimeo('vimeo', {
      byline: false,
      title: false,
      url: vimeoUrl,
    })

    Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(
      dimensions => {
        const [width, height] = dimensions
        this.setState(
          {
            height,
            width,
          },
          () => {
            if (onReady) {
              onReady()
            }
          }
        )
      }
    )
  }

  render() {
    const { vimeoUrl } = this.props
    const { height, imageUrl, width } = this.state
    const aspectRatio = width / height

    return (
      <Container aspectRatio={aspectRatio}>
        <InnerContainer aspectRatio={aspectRatio}>
          {imageUrl && !vimeoUrl && <Image src={`${imageUrl}?w=500`} />}
          {vimeoUrl && <Player id="vimeo" />}
        </InnerContainer>
      </Container>
    )
  }
}
export default Media
