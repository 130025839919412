// @format

import React from 'react'
import styled from 'styled-components'
import logo from './Logo.png'

const Container = styled.div`
  img {
    display: block;
    height: 39px;
  }
`

const Logo = () => {
  return (
    <Container>
      <img src={logo} />
    </Container>
  )
}
export default Logo
