// @format

import React from 'react'
import styled from 'styled-components'
import { media } from '../styles/theme'

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 50px auto;

  a {
    color: ${props => props.theme.color.black};
    &:hover {
      opacity: 0.6;
    }
  }

  input {
    border-radius: 0;
  }

  input[type='email'] {
    width: 100% !important;
    padding: 13px !important;
    font-family: ${props => props.theme.type.family.sans} !important;
    font-size: 15px !important;
    letter-spacing: ${props => props.theme.type.letterSpacing.input} !important;
    line-height: 17px !important;
    border: 1px solid ${props => props.theme.color.black} !important;
    border-right: 1px solid ${props => props.theme.color.black} !important;
    border-bottom: none !important;
    color: ${props => props.theme.color.black} !important;
    background: none !important;

    &:focus {
      background: none !important;
    }

    &::placeholder {
      color: ${props => props.theme.color.black};
      opacity: 0.6;
    }

    &.mce_inline_error {
      border-bottom: 1px solid ${props => props.theme.color.black} !important;
    }

    ${media(0)`
      border-right: none !important;
      border-bottom: 1px solid ${props => props.theme.color.black} !important;
    `};
  }

  input[type='submit'] {
    width: 100% !important;
    padding: 13px !important;
    font-family: ${props => props.theme.type.family.sans};
    font-size: 15px !important;
    font-weight: bold !important;
    letter-spacing: ${props => props.theme.type.letterSpacing.input} !important;
    line-height: 17px !important;
    text-transform: uppercase !important;
    border: 1px solid ${props => props.theme.color.black} !important;
    background: none !important;
    cursor: pointer !important;

    &:hover {
      background: ${props => props.theme.color.black} !important;
      color: ${props => props.theme.color.primary} !important;
    }
  }

  .mc-field-group-email {
    width: 100%;
  }

  .mc-inner {
    position: relative;
    display: flex;
    flex-direction: column;

    ${media(0)`
      flex-direction: row;
    `};
  }

  #mce-responses {
    text-align: center !important;
  }

  .mc-field-group-email > div.mce_inline_error {
    margin-top: 12px !important;
  }

  #mce-responses {
    margin-top: 20px !important;
  }

  #mce-responses,
  .mce_inline_error {
    font-family: ${props => props.theme.type.family.serif} !important;
    font-size: 14px !important;
    font-weight: normal !important;
    letter-spacing: ${props =>
      props.theme.type.letterSpacing.caption} !important;
    line-height: 21px !important;
    background: none !important;
    color: ${props => props.theme.color.black} !important;
  }

  ${media(0)`
    margin: 20px auto;
  `};
`

class NewsletterForm extends React.Component {
  componentDidMount() {
    // Run mailchimp validation script
    const script = document.createElement('script')
    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'
    document.body.appendChild(script)
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <Container>
        <div id="mc_embed_signup" className="mc-signup">
          <form
            action="//one.us15.list-manage.com/subscribe/post?u=4a9c8b58a90a9d4c39dba72b4&amp;id=8406452de8"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div className="mc-inner">
              <div className="mc-field-group mc-field-group-email">
                <input
                  autoComplete="off"
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  placeholder="Your email here"
                />
              </div>
              <div className="mc-field-group">
                <input
                  className="button"
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                />
              </div>
              {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
              <div
                style={{
                  position: 'absolute',
                  left: '-5000px',
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_4a9c8b58a90a9d4c39dba72b4_8406452de8"
                  tabIndex="-1"
                />
              </div>
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{
                  display: 'none',
                }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{
                  display: 'none',
                }}
              />
            </div>
            {/* Mailchimp form validation */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
              (function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';}(jQuery));var $mcj = jQuery.noConflict(true);
            `,
              }}
            />
          </form>
        </div>
      </Container>
    )
  }
}

export default NewsletterForm
