// @format

import React from 'react'
import styled from 'styled-components'
import { media } from '../styles/theme'
import { SettingsContext } from '../contexts/Settings'
import NewsletterForm from './NewsletterForm'
import Link from './Link'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  background: ${props => props.theme.color.primary};
  overflow-x: hidden;

  footer {
    height: 60px;

    ${media(0)`
      height: 100px;
    `};
  }

  header {
    height: 25px;

    ${media(0)`
      height: 100px;
    `};
  }

  footer,
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  footer {
    ul {
      display: flex;
      width: 100%;
      justify-content: space-between;

      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: center;

      li {
        margin: 0 15px;
        display: inline-block;
        vertical-align: bottom;
      }

      ${media(0)`
        display: block;
      `};
    }
  }

  ${media(0)`
    min-height: 100vh;
  `};
`

const Feature = styled.div`
  max-width: 420px;
  position: relative;
  width: 100%;
`

const Intro = styled.div`
  max-width: 420px;
  margin: 0 auto;
  font-family: ${props => props.theme.type.family.serif};
  font-size: 17px;
  line-height: ${props => props.theme.type.lineHeight.caption};
  letter-spacing: ${props => props.theme.type.letterSpacing.caption};
  white-space: pre;
  text-align: center;
`

const ThankYou = styled.div`
  margin: 50px auto;
  font-family: ${props => props.theme.type.family.serif};
  font-size: 14px;
  line-height: 21px;
  letter-spacing: ${props => props.theme.type.letterSpacing.caption};
  text-align: center;
  color: ${props => props.theme.color.black};
  white-space: pre;

  ${media(0)`
    margin: 0;
  `};
`

const Newsletter = props => {
  const showThankYou = window.location.search === '?thank-you'
  const thankYouText =
    'Subscription Confirmed.\nYour subscription to our list has been confirmed.\nThank you for subscribing!'

  return (
    <SettingsContext.Consumer>
      {context => (
        <Container>
          <header />

          <Feature>
            {showThankYou ? (
              <>
                <ThankYou>{thankYouText}</ThankYou>
              </>
            ) : (
              <>
                <Intro>{context.newsletterIntro}</Intro>
                <NewsletterForm />
              </>
            )}
          </Feature>

          <footer>
            <ul>
              <li>
                <Link newWindow={true} to={context.instagramUrl}>
                  Instagram
                </Link>
              </li>
              <li>
                <Link newWindow={true} to={context.latestNewsletterUrl}>
                  Latest Newsletter
                </Link>
              </li>
              <li>
                <Link to={`mailto:${context.email}`}>Contact</Link>
              </li>
            </ul>
          </footer>
        </Container>
      )}
    </SettingsContext.Consumer>
  )
}
export default Newsletter
