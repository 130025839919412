// @format

import React from 'react'
import styled from 'styled-components'
import { media } from '../styles/theme'
import Feature from './Feature'
import Logo from './Logo'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  background: #fff;
  overflow-x: hidden;

  ${media(0)`
    min-height: 100vh;
  `};

  footer {
    height: 25px;
    ${media(0)`
      height: 100px;
    `};
  }

  header {
    flex-shrink: 0;
    height: 100px;
  }

  footer,
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`

class Shop extends React.Component {
  render() {
    return (
      <Container>
        {/* Header */}
        <header>
          <a href="/">
            <Logo />
          </a>
        </header>

        {/* Main image / video */}
        <Feature />

        {/* Footer */}
        <footer />
      </Container>
    )
  }
}
export default Shop
