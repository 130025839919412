import { css } from 'styled-components'

const theme = {
  breakpoints: ['820px'],
  color: {
    primary: '#ffcad9',
    black: '#000',
    white: '#fff',
  },
  type: {
    letterSpacing: {
      caption: '-0.17px',
      input: '1.39px',
      link: '0.32px',
    },
    lineHeight: {
      caption: '24px',
    },
    family: {
      sans: 'Arial Narrow, Arial, sans',
      serif: 'Palatino, Times, serif',
    }
  }
}

export const media = (index: number) => {
  return (...args: any) => css`
    @media (min-width: ${theme.breakpoints[index]}) {
      ${css(...args)};
    }
  `
}

export default theme
