// @format

import _first from 'lodash/fp/first'
import _flow from 'lodash/fp/flow'
import _get from 'lodash/fp/get'
import _isEmpty from 'lodash/fp/isEmpty'
import React, { Component } from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import theme, { media } from './styles/theme'
import Newsletter from './components/Newsletter'
import Shop from './components/Shop'
import { SettingsContext } from './contexts/Settings'
import { CONTENTFUL_ACCESS_TOKEN, CONTENTFUL_SPACE_ID } from './config'

import 'normalize.css'

const contentful = require('contentful')

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;

  ${media(0)`
    position: fixed;
    flex-direction: row;
    height: 100%;
  `};
`

const Column = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 50%;
  overflow-y: auto;

  ${media(0)`
    width: 50%;
    height: 100%;
  `};
`

const Error = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: ${props => props.theme.type.family.serif};
  font-size: 17px;
  line-height: ${props => props.theme.type.lineHeight.caption};
  letter-spacing: ${props => props.theme.type.letterSpacing.caption};
  white-space: pre;
`

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Palatino';
    src: local('Palatino-Roman'), local('PalatinoRomanRegular'),
      url('/fonts/Palatino.woff2') format('woff2'),
      url('/fonts/Palatino.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Arial Narrow';
    src: local('Arial Narrow'), local('ArialNarrow'),
      url('/fonts/ArialNarrow.woff2') format('woff2'),
      url('/fonts/ArialNarrow.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  * {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: #000;
    }
  }

  body, html {
    height: 100%;
    background: ${props => props.theme.color.primary};
  }

  #root {
    height: 100%;
  }
`

class App extends Component {
  state = {
    error: null,
    settings: {},
  }

  componentDidMount() {
    // TODO: initialize contentful client
    const client = contentful.createClient({
      space: CONTENTFUL_SPACE_ID,
      accessToken: CONTENTFUL_ACCESS_TOKEN,
    })

    client
      .getEntries()
      .then(response => {
        const { items } = response

        const settings = _flow(_first, _get('fields'))(items)

        if (settings) {
          this.setState({
            settings,
          })
        }
      })
      .catch(err => {
        this.setState({
          error: "An error has occured - we'll be back shortly!",
        })
      })
  }

  render() {
    const { error, settings } = this.state

    return (
      <ThemeProvider theme={theme}>
        <Container>
          <GlobalStyle />
          {error && <Error>{error}</Error>}
          {!error &&
            !_isEmpty(settings) && (
              <SettingsContext.Provider value={settings}>
                <Column>
                  <Shop />
                </Column>
                <Column>
                  <Newsletter />
                </Column>
              </SettingsContext.Provider>
            )}
        </Container>
      </ThemeProvider>
    )
  }
}

export default App
