// @format

import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import Media from './Media'
import { media } from '../styles/theme'
import { SettingsContext } from '../contexts/Settings'

const Caption = styled.div`
  font-family: ${props => props.theme.type.family.serif};
  font-size: 17px;
  line-height: ${props => props.theme.type.lineHeight.caption};
  letter-spacing: ${props => props.theme.type.letterSpacing.caption};
  white-space: pre;
`

const Container = styled.div`
  margin: 50px 0;
  text-align: center;
  opacity: ${props => (props.visible ? 1 : 0)};

  ${media(0)`
    margin: 0;
  `};
`

class Feature extends React.Component {
  state = {
    ready: false,
  }

  _handleMediaReady = () => {
    this.setState({
      ready: true,
    })
  }

  render() {
    const { ready } = this.state
    return (
      <Container visible={ready}>
        <SettingsContext.Consumer>
          {context => (
            <>
              <Caption>{context.shopCaption}</Caption>
              <Media
                image={context.image}
                onReady={this._handleMediaReady}
                vimeoUrl={context.vimeoUrl}
              />
              <Button to={context.shopUrl}>{context.shopButtonLabel}</Button>
            </>
          )}
        </SettingsContext.Consumer>
      </Container>
    )
  }
}
export default Feature
