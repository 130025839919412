// @format

import React from 'react'
import styled from 'styled-components'

const Container = styled.a`
  font-family: Arial Narrow, sans;
  font-size: 17px;
  text-decoration: none;
  letter-spacing: ${props => props.theme.type.letterSpacing.link};

  border-bottom: 1px solid ${props => props.theme.color.black};
  color: ${props => props.theme.color.black};

  &:focus,
  &:hover {
    opacity: 0.6;
  }
`

const Link = props => {
  const { children, newWindow, to } = props
  return (
    <Container
      href={to}
      rel={newWindow ? 'nofollow noopener noreferrer' : undefined}
      target={newWindow ? '_blank' : undefined}
    >
      {children}
    </Container>
  )
}
export default Link
