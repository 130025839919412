// @format

import React from 'react'

export const SettingsContext = React.createContext({
  email: '',
  image: {},
  instagramUrl: '',
  latestNewsletterUrl: '',
  newsletterIntroText: '',
  shopCaption: '',
  shopUrl: '',
  vimeoUrl: '',
})
