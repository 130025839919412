// @format

import React from 'react'
import styled from 'styled-components'

const Container = styled.a`
  display: inline-block;
  padding: 13px;
  font-family: ${props => props.theme.type.family.sans};
  font-size: 15px;
  font-weight: bold;
  letter-spacing: ${props => props.theme.type.letterSpacing.input};
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid ${props => props.theme.color.black};
  color: ${props => props.theme.color.black};
  cursor: pointer;

  &:focus,
  &:hover {
    background: ${props => props.theme.color.black};
    color: ${props => props.theme.color.white};
  }
`

const Button = props => {
  const { children, to } = props
  return (
    <Container href={to} rel="nofollow noopener noreferrer" target="_blank">
      {children}
    </Container>
  )
}
export default Button
